import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import {jsx, css} from '@emotion/react'
import Layout from "../components/layout_under"


const RezeptLayout = ({data}) => {
  return (
    <div>
      <Layout>    
        {
        data.allMdx.nodes.map((node) => (
              <article key={node.id}>
                <h2>
                  <Link to={`/produkte/${node.slug}`}>
                  {node.frontmatter.title}
                  </Link>
                </h2>
                <p>Preis: {node.frontmatter.preis}</p>
              </article>
            )
          )
        }
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          preis
          title
        }
        id
        slug
      }
    }
  }
`

export default RezeptLayout